<template>
   <el-radio-group v-bind="$attrs" v-on="$listeners">
    <el-radio :label="1">男</el-radio>
    <el-radio :label="2">女</el-radio>
  </el-radio-group>
</template>
<script>
export default {

}
</script>
