
import BaseSelect from '@/components/common/base-select/base-select.vue'
import SexRadio from '../sex-radio'
import BirthdayRadio from '../birthday-radio.vue'
import MarriageRadio from '../marriage-radio.vue'
import HomeAddress from '../home-address.vue'
import { getDictLists } from '@/filters/fromDict'

// 人员信息
export const formConfig = () => {
  return [
    {
      label: '姓名',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '身份证',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '电话号码',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      }
    }
  ]
}
// 人员表格
export const columns = () => {
  return [
    {
      label: '序号',
      prop: 'index',
      width: '50px'
    },
    {
      label: '姓名',
      prop: 'businessNo',
      HiddenOverflow: false
    },
    {
      label: '身份证',
      prop: 'documentName',
      HiddenOverflow: false,
      align: 'left'
    },
    {
      label: '电话号码',
      prop: 'fileName',
      HiddenOverflow: false,
      align: 'left'
    },
    {
      label: '职位',
      prop: 'fileName',
      HiddenOverflow: false,
      align: 'left'
    },
    {
      label: '人员类型',
      prop: 'fileName',
      HiddenOverflow: false,
      align: 'left'
    },
    {
      label: '通讯地址',
      prop: 'fileName',
      HiddenOverflow: false,
      align: 'left'
    },
    {
      label: '操作',
      prop: 'action',
      HiddenOverflow: false,
      align: 'left'
    }
  ]
}
// 基本信息
export const basicInfoConfig = (content) => {
  return [
    {
      label: '姓名',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      },
      rules: [
        { required: true, message: '请输入姓名', trigger: ['blur', 'change'] }
      ]
    },
    {
      label: '身份证',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '电话号码',
      prop: 'mobilePhoneNo',
      width: '80px',
      attrs: {
        placeholder: '请输入',
        maxLength: 11
      },
      rules: [
        { required: true, message: '请输入电话号码', trigger: ['blur', 'change'] }
      ],
      on: {
        input: data => {
          // eslint-disable-next-line no-useless-escape
          content.basicData.mobilePhoneNo = data.replace(/[\u4E00-\u9FA5]|[a-zA-Z]|&quot;|&lt;|&gt;|[\（\）\……\~\`\·\|\【\】\》\《\'\!\！\{\}\#\$\￥\%\^\&\*\(\)\[\]\:\;\：\；\\\‘\“\”\’\,\，\.\。\/\、\?\？\_\-\——\=\+]|@|/g, '').replace(/\s+/g, '')
        }
      }
    },
    {
      label: '职位',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      },
      rules: [
        { required: true, message: '请输入职位', trigger: ['blur', 'change'] }
      ]
    },
    {
      label: '人员类别',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '通讯地址',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      },
      rules: [
        { required: true, message: '请输入通讯地址', trigger: ['blur', 'change'] }
      ],
      span: 18
    }
  ]
}
// 企业相关信息
export const enterpriseConfig = (content) => {
  return [
    {
      label: '所属公司',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请选择'
      },
      rules: [
        { required: true, message: '请选择所属公司', trigger: ['blur', 'change'] }
      ],
      tag: BaseSelect
    },
    {
      label: '内部层次',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请选择'
      },
      rules: [
        { required: true, message: '请选择内部层次', trigger: ['blur', 'change'] }
      ],
      tag: BaseSelect
    },
    {
      label: '职务',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      },
      rules: [
        { required: true, message: '请输入职务', trigger: ['blur', 'change'] }
      ]
    },
    {
      label: '称谓',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      },
      rules: [
        { required: true, message: '请输入称谓', trigger: ['blur', 'change'] }
      ]
    },
    {
      label: '负责板块',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      },
      rules: [
        { required: true, message: '请输入负责板块', trigger: ['blur', 'change'] }
      ]
    },
    {
      label: '相关部门',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      },
      rules: [
        { required: true, message: '请输入相关部门', trigger: ['blur', 'change'] }
      ]
    },
    {
      label: '是否挂靠',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '公司座机',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '办公地址',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      },
      rules: [
        { required: true, message: '请输入办公地址', trigger: ['blur', 'change'] }
      ]
    },
    {
      label: '详情地址',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      },
      rules: [
        { required: true, message: '请输入详情地址', trigger: ['blur', 'change'] }
      ],
      span: 18
    }
  ]
}
// 联系人详细信息
export const contactInfoConfig = (content) => {
  return [
    {
      label: '性别',
      prop: 'sex',
      width: '80px',
      attrs: {
        placeholder: '请选择'
      },
      rules: [
        { required: true, message: '请选择所属公司', trigger: ['blur', 'change'] }
      ],
      tag: SexRadio
    },
    {
      label: '年龄',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请选择'
      }
    },
    {
      label: '身份证号',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '生日',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请选择'
      },
      rules: [
        { required: true, message: '请选择生日', trigger: ['blur', 'change'] }
      ],
      tag: BirthdayRadio
    },
    {
      label: '出生证明',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请选择',
        type: 'date'
      },
      rules: [
        { required: true, message: '请选择出生证明', trigger: ['blur', 'change'] }
      ],
      tag: 'el-date-picker'
    },
    {
      label: '籍贯',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '出生地',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '星座',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '民族',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '邮箱地址',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      }
    }
  ]
}
// 学历与工作经历
export const educationJobConfig = (content) => {
  return [
    {
      label: '最高学历',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      },
      tab: BaseSelect
    },
    {
      label: '专业',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '毕业院校',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入'
      }
    },
    {
      label: '工作经历',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        placeholder: '请输入',
        maxLength: 2000,
        type: 'textarea',
        rows: 3
      },
      span: 24
    }
  ]
}
// 家庭情况
export const familyConfig = (content) => {
  return [
    {
      label: '婚姻状态',
      prop: 'sex',
      width: '80px',
      attrs: {
        placeholder: '请选择'
      },
      span: 24,
      tag: MarriageRadio
    },
    {
      label: '家庭地址',
      prop: 'businessNo',
      width: '80px',
      attrs: {
        addressvalue: content.familyData,
        type: 'add',
        placeholder: '请选择',
        address: getDictLists('ZDZD0013')
      },
      span: 24,
      tag: HomeAddress
    },
    {
      label: '配偶情况',
      prop: 'sex',
      width: '80px',
      attrs: {
        placeholder: '请选择',
        maxLength: 100
      },
      span: 24
    },
    {
      label: '子女情况',
      prop: 'sex',
      width: '80px',
      attrs: {
        placeholder: '请选择',
        maxLength: 100
      },
      span: 24
    },
    {
      label: '父母情况',
      prop: 'sex',
      width: '80px',
      attrs: {
        placeholder: '请选择',
        maxLength: 100
      },
      span: 24
    }
  ]
}
