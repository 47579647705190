var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-dialog',{attrs:{"visible":_vm.currentVisible,"width":"1200px","showFooter":false,"title":"新增人员信息"},on:{"update:visible":function($event){_vm.currentVisible=$event}}},[_c('text-title',{attrs:{"index":"01","label":"基本信息"}}),_c('base-form',{ref:"formData",staticClass:"formStyle",attrs:{"componentList":_vm.basicInfoConfig,"formAttrs":{
        model: _vm.basicData,
        labelWidth: '90px',
      },"showBtns":false}}),_c('el-collapse',{model:{value:(_vm.activeNames),callback:function ($$v) {_vm.activeNames=$$v},expression:"activeNames"}},[_c('el-collapse-item',{attrs:{"name":"02"}},[_c('template',{slot:"title"},[_c('text-title',{attrs:{"index":"02","label":"企业相关信息"}})],1),_c('base-form',{ref:"formData",staticClass:"formStyle",attrs:{"componentList":_vm.enterpriseConfig,"formAttrs":{
            model: _vm.enterpriseData,
            labelWidth: '90px',
          },"showBtns":false}})],2),_c('el-collapse-item',{attrs:{"name":"03"}},[_c('template',{slot:"title"},[_c('text-title',{attrs:{"index":"03","label":"联系人相关信息"}})],1),_c('base-form',{ref:"formData",staticClass:"formStyle",attrs:{"componentList":_vm.contactInfoConfig,"formAttrs":{
            model: _vm.contactInfoData,
            labelWidth: '90px',
          },"showBtns":false}})],2),_c('el-collapse-item',{attrs:{"name":"04"}},[_c('template',{slot:"title"},[_c('text-title',{attrs:{"index":"04","label":"学历与工作经历"}})],1),_c('base-form',{ref:"formData",staticClass:"formStyle",attrs:{"componentList":_vm.educationJobConfig,"formAttrs":{
            model: _vm.educationJobData,
            labelWidth: '90px',
          },"showBtns":false}})],2),_c('el-collapse-item',{attrs:{"name":"05"}},[_c('template',{slot:"title"},[_c('text-title',{attrs:{"index":"05","label":"家庭情况"}})],1),_c('base-form',{ref:"formData",staticClass:"formStyle",attrs:{"componentList":_vm.familyConfig,"formAttrs":{
            model: _vm.familyData,
            labelWidth: '90px',
          },"showBtns":false}})],2),_c('el-collapse-item',{attrs:{"name":"06"}},[_c('template',{slot:"title"},[_c('text-title',{attrs:{"index":"06","label":"生活方式"}})],1),_c('base-form',{ref:"formData",staticClass:"formStyle",attrs:{"componentList":_vm.educationJobConfig,"formAttrs":{
            model: _vm.educationJobData,
            labelWidth: '90px',
          },"showBtns":false}})],2),_c('el-collapse-item',{attrs:{"name":"07"}},[_c('template',{slot:"title"},[_c('text-title',{attrs:{"index":"07","label":"个人印象"}})],1),_c('base-form',{ref:"formData",staticClass:"formStyle",attrs:{"componentList":_vm.educationJobConfig,"formAttrs":{
            model: _vm.educationJobData,
            labelWidth: '90px',
          },"showBtns":false}})],2)],1),_c('template',{slot:"footer"},[_c('base-button',{attrs:{"label":"保 存"}}),_c('base-button',{attrs:{"label":"关 闭","type":"default"},on:{"click":function($event){_vm.currentVisible = false}}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }