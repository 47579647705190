<template>
  <div>
    <!-- 人员明细信息 -->
    <base-dialog
      :visible.sync="currentVisible"
      width="1200px"
      :showFooter="false"
      title="新增人员信息"
    >
    <!-- 基本信息 -->
      <text-title index='01' label="基本信息"></text-title>
      <base-form
        :componentList="basicInfoConfig"
        :formAttrs="{
          model: basicData,
          labelWidth: '90px',
        }"
        class="formStyle"
        :showBtns="false"
        ref="formData">
      </base-form>
      <el-collapse v-model="activeNames">
        <!-- 企业相关信息 -->
        <el-collapse-item name="02">
          <template slot="title">
            <text-title index='02' label="企业相关信息"></text-title>
          </template>
          <base-form
            :componentList="enterpriseConfig"
            :formAttrs="{
              model: enterpriseData,
              labelWidth: '90px',
            }"
            class="formStyle"
            :showBtns="false"
            ref="formData">
          </base-form>
        </el-collapse-item>
        <!-- 联系人相关信息 -->
        <el-collapse-item name="03">
          <template slot="title">
            <text-title index='03' label="联系人相关信息"></text-title>
          </template>
          <base-form
            :componentList="contactInfoConfig"
            :formAttrs="{
              model: contactInfoData,
              labelWidth: '90px',
            }"
            class="formStyle"
            :showBtns="false"
            ref="formData">
          </base-form>
        </el-collapse-item>
        <!-- 学历与工作经历 -->
        <el-collapse-item name="04">
          <template slot="title">
            <text-title index='04' label="学历与工作经历"></text-title>
          </template>
          <base-form
            :componentList="educationJobConfig"
            :formAttrs="{
              model: educationJobData,
              labelWidth: '90px',
            }"
            class="formStyle"
            :showBtns="false"
            ref="formData">
          </base-form>
        </el-collapse-item>
        <!-- 家庭情况 -->
        <el-collapse-item name="05">
          <template slot="title">
            <text-title index='05' label="家庭情况"></text-title>
          </template>
          <base-form
            :componentList="familyConfig"
            :formAttrs="{
              model: familyData,
              labelWidth: '90px',
            }"
            class="formStyle"
            :showBtns="false"
            ref="formData">
          </base-form>
        </el-collapse-item>
        <!-- 生活方式 -->
        <el-collapse-item name="06">
          <template slot="title">
            <text-title index='06' label="生活方式"></text-title>
          </template>
          <base-form
            :componentList="educationJobConfig"
            :formAttrs="{
              model: educationJobData,
              labelWidth: '90px',
            }"
            class="formStyle"
            :showBtns="false"
            ref="formData">
          </base-form>
        </el-collapse-item>
        <!-- 个人印象 -->
        <el-collapse-item name="07">
          <template slot="title">
            <text-title index='07' label="个人印象"></text-title>
          </template>
          <base-form
            :componentList="educationJobConfig"
            :formAttrs="{
              model: educationJobData,
              labelWidth: '90px',
            }"
            class="formStyle"
            :showBtns="false"
            ref="formData">
          </base-form>
        </el-collapse-item>
      </el-collapse>
      <template slot="footer">
        <base-button label='保 存'></base-button>
        <base-button
          label="关 闭"
          type="default"
          @click="currentVisible = false"
        ></base-button>
      </template>
    </base-dialog>
  </div>
</template>
<script>
import TextTitle from '@/components/packages/text-title/text-title.vue'
import BaseDialog from '@/components/common/base-dialog/base-dialog.vue'
import BaseForm from '@/components/common/base-form/base-form.vue'
import BaseButton from '@/components/common/button/base-button/base-button.vue'
import { businessManageApi } from '@/api/businessApi'
import { basicInfoConfig, enterpriseConfig, contactInfoConfig, educationJobConfig, familyConfig } from './utils/personnel-details'
export default {
  props: {
    visible: Boolean
  },
  components: {
    TextTitle,
    BaseDialog,
    BaseForm,
    BaseButton
  },
  data () {
    return {
      queryParas: {},
      addVisible: false,
      loadCount: 0,
      tableData: [],
      basicData: {}, // 基本信息
      enterpriseData: {}, // 企业相关信息
      contactInfoData: {}, // 联系人相关信息
      educationJobData: {}, // 学历与工作经历
      familyData: {}, // 家庭情况
      activeNames: ['02', '03', '04', '05', '06', '07']
    }
  },
  computed: {
    currentVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    },
    api () {
      return businessManageApi
    },
    // 基本信息
    basicInfoConfig () {
      return basicInfoConfig(this)
    },
    // 企业相关信息
    enterpriseConfig () {
      return enterpriseConfig(this)
    },
    // 联系人相关信息
    contactInfoConfig () {
      return contactInfoConfig(this)
    },
    // 学历与工作经历
    educationJobConfig () {
      return educationJobConfig(this)
    },
    // 家庭情况
    familyConfig () {
      return familyConfig(this)
    }
  },
  methods: {
    handleFilter () {

    },
    resetFrom () {

    }
  }
}
</script>
<style lang="scss" scoped>

</style>
