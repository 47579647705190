<template>
  <el-radio-group v-bind="$attrs" v-on="$listeners">
   <el-radio :label="1">未婚</el-radio>
   <el-radio :label="2">已婚</el-radio>
   <el-radio :label="3">离婚</el-radio>
   <el-radio :label="4">丧偶</el-radio>
 </el-radio-group>
</template>
<script>
export default {

}
</script>
