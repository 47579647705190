<template>
  <el-radio-group v-bind="$attrs" v-on="$listeners">
   <el-radio :label="1">阴历</el-radio>
   <el-radio :label="2">阳历</el-radio>
 </el-radio-group>
</template>
<script>
export default {

}
</script>
